<template>
  <div ref="panel" style="width: 100%;height: 100%;">

  </div>
</template>
<script>

import distribute from './mixins/distribute'

export default {

  name: 'in-school-distribute',

  mixins: [distribute],

  methods: {

    _setOption () {
      const myChart = this.chartInstance
      myChart.resize()
      const options = {

        visualMap: {
          show: false,
          realtime: false,
          calculable: true,
          inRange: {
            color: ['#4998FE']
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function (e) {
            const data = e.data
            const html = `
<div class="tooltip1">
  <div>
    <p>${data.name}</p>
    <p>学生：${data.currentStudentNum}，教职：${data.currentTeacherNum}</p>
  </div>
</div>
`
            return html
          },
          backgroundColor: 'transparent',
          borderRadius: 4,
          borderWidth: 0,
          padding: 0,
          shadowColor: 'rgba(0, 0, 0, 0)',
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowQuality: 0,
          textStyle: {
            color: '#FFC000'
          }
        },

        series: [
          {
            name: '中国',
            map: this.city,
            zoom: 1, // 当前视角的缩放比例
            roam: true, // 是否开启平游或缩放
            scaleLimit: { // 滚轮缩放的极限控制
              min: 0,
              max: 4
            },
            label: {
              show: true,
              color: '#FFBA00'
            },

            lineStyle: {
              type: 'solid',
              color: '#000',
              width: 0.5,
              opacity: 1
            },
            type: 'map',
            itemStyle: {
              normal: {
                shadowColor: '#0B368F',
                shadowBlur: 10
              }
            },
            data: this.getData()
          }
        ]
      }
      myChart.setOption(options)
    },

    getData () {
      const data = []
      const list = this.districtList
      list.forEach(item => {
        let dataItem = {
          name: item.properties.name,
          currentStudentNum: 0,
          currentTeacherNum: 0,
          value: 0
        }
        this.data.forEach(row => {
          if (row.eduName === dataItem.name) {
            dataItem = { ...dataItem, ...row }
          }
        })
        data.push(dataItem)
      })
      return data
    }
  }
}
</script>
<style lang="scss">
.tooltip1 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(/static/images/tooltip.png);
  padding: 0.2rem;

  p {
    color: #FFC000;
    font-size: 0.14rem;
  }

  img {
    width: 0.29rem;
    height: 0.21rem
  }
}
</style>
